.colorhover{
    -webkit-filter: grayscale(100%);
}

.colorhover:hover{
    -webkit-filter : grayscale(0%);
}

@media screen and (max-width:600px){
    .partnerText{
        font-weight: 500 !important;
        text-align: center !important;
        margin-bottom:10px !important;
    }
    .partnerWrapper{
        padding:0 !important;
        margin: 80px 7px !important;
    }
}