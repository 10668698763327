

@media screen and (max-width:600px){
    .leaderboard{
        background-size:contain !important;
        margin: 0 !important;
        padding: 0 !important;
    }
    .leaderboardContainers{
        margin-top: 30px !important;
    }
    .leaderboard1{
     width: 100px !important;
    }
    .leaderboard1 img{
        height: 70px !important;
        width: 70px !important;
    }
    .leaderboard1 button{
        padding: 5px 15px !important;
        font-size: 12px;
    }
   .leaderboard2{
    width: 100px !important;
    bottom: 30px !important;
   }
   .leaderboard2 img{
    height: 70px !important;
    width: 70px !important;
}
.leaderboard2 button{
    padding: 5px 15px !important;
    font-size: 12px;
}
   .leaderboard3{
    width: 100px !important;
    bottom: 10px !important;
   }
   .leaderboard3 img{
    height: 70px !important;
    width: 70px !important;
}
.leaderboard3 button{
    padding: 5px 15px !important;
    font-size: 12px;
}
.rupees{
    font-size: 20px !important;
}

}