#div1{
    display:none;
}
#div2{
    display:none;
}
#div3{
    display:none;
}


/* #divLearn div:nth-of-type(1):hover + .div1{flex-grow:1; display:block; color:red;}
#divLearn div:nth-of-type(2):hover{flex-grow:1;}
#divLearn div:nth-of-type(3):hover{flex-grow:1;} */

#container1:hover + #div1{flex-grow:1; display:block; color:red;}
#container2:hover + #div2{flex-grow:3; display:block; color:red;}
#container3:hover + #div3{flex-grow:1; display:block; color:red;}

@media screen and (max-width:600px){
    .learnWrapper{
        padding: 45px 15px !important;
        margin: 10px 0 !important;
    }
}