.socialhover{
    -webkit-filter: grayscale(100%);
}

.socialhover:hover{
    -webkit-filter : grayscale(0%);
}

@media screen and (max-width:600px){
    .socialWrapper{
        margin: 0 20px !important;
        padding: 10px !important;
    }
    .socialhover{
       margin: 0 !important;
    }
    .socialImagesWrapper{
        margin-top: 30px !important;
    }
    .socialImages{
        width: 140px !important;
        height: 140px !important;
    }
}