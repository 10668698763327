

@media screen and (max-width:600px){
    .stepsTitle{
        font-size: 28px !important;
        text-align: center;
    }
    .stepsDesc{
        font-size: 14px !important;
        text-align: center;
    }
    .playteststepsimgwrap{
         padding: 20px 50px !important;
    }
    .playteststepimg{
         height: 340px !important;
         width: 200px !important;
    }

}